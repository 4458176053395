import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import Menu from '../../commons/Menu'
import BurgerMenu from '../../commons/BurgerMenu'
import Header from '../../commons/Header'
import Footer from '../../commons/Footer'
import AlertItem from '../../components/AlertItem'
import { ReactComponent as IconAlert } from '../../images/icn_alert.svg'
import './Alert.scss'
import { useQueryParams, NumberParam, StringParam } from 'use-query-params'
import { useAlert } from 'react-alert'
import { list } from '../../lib/fetch/alerts'
import Spinner from '../../commons/Spinner'
import { useAuth } from '../../commons/Auth'

function Alerts({ match, history }) {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [t] = useTranslation()
  const alert = useAlert()
  const [auth = {}] = useAuth();
  const { token = '' } = auth;

  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
    order: StringParam,
    sort: StringParam,
    limit: NumberParam,
    search: StringParam,
  })

  const fetchAlerts = async query => {
    setIsLoading(true)
    const { error, data, headers } = await list(token, query)

    if (error) {
      setIsLoading(false)
      setData([])
      setTotal(0)
      alert.error(t('COMMON:GENERIC_ERROR'))
      return
    }

    setIsLoading(false)
    setData(data)
    setTotal(Number(headers['x-total-count']))
  }

  useEffect(() => {
    fetchAlerts(query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return (
    <div className="App">
      <Menu match={match} />
      <BurgerMenu />

      {isLoading ? (
        <div className="u-center-center">
          <Spinner />
        </div>
      ) : (
        <div className="corpo">
          <section>
            <Header title={t('COMMON:ALERT')} icon={<IconAlert fill="#2F5D5D" />} />
          </section>

          <hr />

          <Card>
            <CardBody>
              {data.length > 0 ? (
                <div className="row">
                  {data.map((alert, index) => {
                    return <AlertItem key={index} data={alert} history={history} />
                  })}
                </div>
              ) : (
                <div className="u-center-center">Nessun alert da visualizzare</div>
              )}
            </CardBody>
          </Card>

          <Footer />
        </div>
      )}
    </div>
  )
}

export default withRouter(Alerts)
