import React from 'react'
import './AlertItem.scss'
import moment from 'moment'
import { ReactComponent as AlertIcon } from '../../images/icn_alert.svg'
import { formatName } from '../../lib/helpers/profiles'

function getAlertText(type) {
  switch (type) {
    case 'paymentExceeding':
      return (
        <p>
          È stata effettuata una transazione con costo superiore a <strong>200,00 €</strong>
        </p>
      )
    case 'adminTravelClose':
      return (
        <p>
          Spedizione conclusa da Amministrazione <strong>SiWeGO</strong>
        </p>
      )
    case 'adminTravelCancel':
      return (
        <p>
          Spedizione cancellata da Amministrazione <strong>SiWeGO</strong>
        </p>
      )
    case 'monthlyTransactions':
      return (
        <p>
          Un utente ha superato <strong>10</strong> transazioni mensili
        </p>
      )
    case 'yearlyTotalTransactions':
      return (
        <p>
          Un utente ha superato <strong>2000 €</strong> in transazioni annuali
        </p>
      )
    default:
      return null
  }
}

const AlertItem = ({ data, history }) => {
  const { createdAt, type, profile = {}, travel = {} } = data
  const { id: travelId } = travel

  function gotToTravel(id) {
    history.push(`/deliveries/${id}${type ? `?alertType=${type}` : ''}`);
  }

  return (
    <div className="col-sm-12 col-md-6">
      <div className="cont-grey">
        <div className="cont-grey-header">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <h6>
                <AlertIcon fill="#2F5D5D" />
                {formatName(profile)}
              </h6>
            </div>
            <div className="col-sm-12 col-md-6 text-right">
              <h6>{moment(createdAt).format('D MMMM YYYY, HH:mm')}</h6>
            </div>
          </div>
        </div>
        <div className="cont-grey-body">
          {getAlertText(type)}
          <div className="alert-buttons">
            <button onClick={() => gotToTravel(travelId)} className="btn btn-logout">
              Visualizza spedizione
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AlertItem
