import axios from "axios";
import qs from "qs";

export const list = async (token, params = {}) => {
  const {
    offset = 0,
    limit = process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT,
  } = params;
  const queryString = { offset, limit };

  try {
    const response = await axios({
      method: "get",
      url: `${
        process.env.REACT_APP_API_INVOICES_URL
      }/findInvoices?${qs.stringify(queryString)}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const getInvoicePdf = async (token, invoiceId) => {
  try {
    const { data: file } = await axios.get(
      `${process.env.REACT_APP_API_INVOICES_URL}/getInvoicePdf`,
      {
        responseType: "blob",
        params: { invoiceId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return file;
  } catch (error) {
    return { error };
  }
};
