import axios from 'axios'
import qs from 'qs'

const fetchDashboard = async (token, params = {}) => {
  const { year } = params
  const queryString = { year }

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/dashboard?${qs.stringify(queryString)}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export default fetchDashboard
