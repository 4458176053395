import React from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconDownload } from '../../images/icn-download.svg';
import { saveAs } from 'file-saver'
import { getInvoicePdf } from '../../lib/fetch/invoices';
import { useAuth } from '../Auth'

function FieldButton({value}) {
  const { t } = useTranslation('INVOICES');
  const [{ token } = {}] = useAuth()
  
  async function handleClick(){
    const invoiceId = value;

    const file = await getInvoicePdf(token, invoiceId);

    const blob = new Blob([file], {type: 'application/pdf'});

    saveAs(blob, `Fattura_${invoiceId}.pdf`);
  }


  return (
    <Button className="btn-invoice"  onClick={handleClick}>
      <IconDownload fill='#74842C' />{t('BTN_DOWNLOAD')}
    </Button>
  );
}

export default FieldButton;
