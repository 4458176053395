import React from 'react';
import Proptypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Card, Button, CardHeader, CardFooter, CardBody, CardTitle,
  Form, FormGroup, Label, Input, FormText, CustomInput
} from 'reactstrap';
import useForm from 'react-hook-form';
import LogoHeader from '../../commons/LogoHeader';
import './Signup.scss';

function SignupPresentational({ match, onSignup }) {
  const { handleSubmit, register, errors } = useForm();

  return (
    <div className="App">
      <LogoHeader match={match} />

      <section>
        <div className="container">

          <Card className="u-center-horizontal login-card">
            <CardHeader>
              <CardTitle>
                Non hai ancora un account?<br />
                <span>Registrati ora</span>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit(onSignup)}>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    type="email"
                    name="email"
                    innerRef={register({
                      required: 'Campo obbligatorio',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Indirizzo email non valido"
                      }
                    })}
                  />
                  {
                    errors.email && errors.email.message &&
                    <FormText>{errors.email.message}</FormText>
                  }
                </FormGroup>

                <FormGroup>
                  <Label>Password</Label>
                  <Input
                    type="password"
                    name="password"
                    innerRef={register({
                      required: 'Campo obbligatorio',
                      pattern: {
                        minLength: 6,
                        maxLength: 20,
                        message: "Password non valida"
                      }
                    })}
                  />
                  {
                    errors.password && errors.password.message &&
                    <FormText>{errors.password.message}</FormText>
                  }
                </FormGroup>

                <FormGroup>
                  <div>
                    <CustomInput type="checkbox" id="Checkbox" label="Accetta i termini e le condizioni di utilizzo" />
                  </div>
                </FormGroup>
                <Button className="u-center-horizontal">Registrati</Button>
              </Form>
            </CardBody>
            <CardFooter>
              <div className="row">
                <div className="col-6">Hai già un account?</div>
                <div className="col-6 text-right">
                  <Link to={'/'}>Login <i className="fas fa-arrow-right" /></Link>
                </div>
              </div>
            </CardFooter>
          </Card>

        </div>
      </section>
    </div>
  );
}

SignupPresentational.propTypes = {
  onSignup: Proptypes.func.isRequired,
};

export default SignupPresentational;
