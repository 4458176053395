import React from 'react'
import { useAuth } from '../Auth';
// import Lang from '../Lang'
import './Header.scss'

function Header({ title, icon }) {
  const [auth = {}, dispatch] = useAuth();
  const { email = '' } = auth;
  const logout = () => dispatch({ type: 'logout' });

  return (
    <div className="header-crm">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <h2>
            {icon}
            {title}
          </h2>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <div className="cont-header-element">
            {/* <Lang /> */}
            <button onClick={() => logout()} className="btn btn-logout">Logout</button>
            {email && <p><span>{email}</span></p>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
