import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import Menu from '../../commons/Menu';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import { ReactComponent as IconCookie } from '../../images/icn_privacy.svg';
import './Terms.scss';

function Terms({match}) {
    const [t] = useTranslation('TERMS');
  return (
      <div className="App">
      <Menu match={match} />
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('TITLE')}
            icon={<IconCookie fill='#2F5D5D' />}
          />
        </section>

        <hr />

        <Card>
            <CardBody dangerouslySetInnerHTML={{__html: t('TERMS_AND_CONDITIONS_TEXT')}}>
              
            </CardBody>
          </Card>

        <Footer />
      </div>
    </div>
  )
}

export default Terms;
