import React, { useEffect, useState } from 'react'
import { Card, CardBody, Spinner } from 'reactstrap'
import ProfileTransactionsFilters from '../../commons/ProfileTransactionsFilters'
import { useQueryParams, StringParam } from 'use-query-params'
import { getKpi } from '../../lib/fetch/profiles'
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next'
import moment from 'moment';
import Kpi from '../../commons/Kpi'
import { useAuth } from '../../commons/Auth'

function ProfileTransactions({ profile }) {
  const { id } = profile || {};
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const alert = useAlert()
  const [t] = useTranslation()
  const [auth = {}] = useAuth()
  const { token = '' } = auth

  const [query, setQuery] = useQueryParams({
    from: StringParam,
    to: StringParam,
  })

  const {
    from = moment().subtract(1, 'year').format('YYYY-MM-DD'),
    to = moment().format('YYYY-MM-DD'),
  } = query;

  useEffect(() => {
    const fetchKPI = async () => {
      setLoading(true)
      const { error, data } = await getKpi(token, { id, from, to })

      if (error) {
        setData({})
        setLoading(false)
        alert.error(t('COMMON:GENERIC_ERROR'))
        return
      }

      setData(data)
      setLoading(false)
    }

    if (id) {
      fetchKPI()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, id])

  const {
    tot_received = 0,
    tot_to_be_received = 0,
    tot_payed = 0,
    shipment_count = 0,
    transport_count = 0,
  } = data || {}

  return (
    <Card>
      <CardBody style={{ minHeight: '400px' }}>
        <div className="m-b-50">
          <ProfileTransactionsFilters label="prova" query={{ from, to }} onFilter={setQuery} />
        </div>
        {
          loading ? <div className="u-center-center"><Spinner /></div>
            :
            <>
              <Kpi title="N. spedizioni completate" unitLabel="" value={shipment_count} />
              <Kpi title="N. trasporti completati" unitLabel="" value={transport_count} />
              <Kpi title="Tot. pagato" unitLabel="euro" value={tot_payed} />
              <Kpi title="Tot. incassato" unitLabel="euro" value={tot_received} />
              <Kpi title="Tot. da incassare" unitLabel="euro" value={tot_to_be_received} />
            </>
        }
      </CardBody>
    </Card>
  )
}

export default ProfileTransactions
