import './Table.scss'
import React, { cloneElement } from 'react'
import PropTypes from 'prop-types'
import { Table as ReactstrapTable } from 'reactstrap'
import get from 'lodash.get'
import { DataTableHeader } from './DataTableHeader'

function EmptyRow({ colSpan = 1, text = '' }) {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="text-center">{text}</div>
      </td>
    </tr>
  )
}

function Row({ children, data, onRowClick }) {
  const childrenComponents = Array.isArray(children) ? children : [children]

  return (
    <tr onClick={() => typeof onRowClick === 'function' && onRowClick(data)}>
      {childrenComponents.map((child = {}, key) => {
        const { props = {} } = child
        const { source = {} } = props

        let value = ''

        if (source && typeof source === 'string' && typeof data === 'object') {
          value = get(data, source, '')
        } else if (source && typeof source === 'object' && typeof data === 'object') {
          value = source.map(src => get(data, src, '')).join(' ')
        }

        return (
          <td key={key} {...props}>
            {cloneElement(child, { ...props, value, data })}
          </td>
        )
      })}
    </tr>
  )
}

function Table(props = {}) {
  const { 
    children,
    data = [],
    isLoading = false,
    loadingText = 'Loading',
    emptyText = 'No data',
    onRowClick,
    query,
    onFilter
  } = props

  const getData = () => {
    return data.length > 0 ? (
      data.map((row, key) => (
        <Row key={key} data={row} onRowClick={onRowClick}>
          {children}
        </Row>
      ))
    ) : (
      <EmptyRow colSpan={children.length} text={emptyText} />
    )
  }

  return (
    <ReactstrapTable responsive hover className="data-table">
      <DataTableHeader query={query} onFilter={onFilter}>{children}</DataTableHeader>

      <tbody>{isLoading ? <EmptyRow colSpan={children.length} text={loadingText} /> : getData()}</tbody>
    </ReactstrapTable>
  )
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  emptyText: PropTypes.string,
  onRowClick: PropTypes.func,
}

export default Table
