import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Form, FormGroup, Label } from 'reactstrap';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import Menu from '../../commons/Menu';
import BurgerMenu from '../../commons/BurgerMenu';
import { useAuth } from '../../commons/Auth';
import { getMailtoAnchor } from '../../lib/helpers/mailto';
import { ReactComponent as IconProfile } from '../../images/icn_profilo.svg';
import './Info.scss';

const EMAIL = process.env.REACT_APP_EMAIL_SUPPORT;

function Info() {
  const [t] = useTranslation('PROFILE');
  const [{ profile = {} } = {}] = useAuth();
  const { first_name, last_name, email, role } = profile;

  return (
    <div className="App">
      <Menu />

      <div className="corpo">
        <section>
          <Header
            title={t('COMMON:PROFILE')}
            icon={<IconProfile fill='#2F5D5D' />}
          />
          <Card>
            <CardBody>
              <Form className="m-b-35 profile-form">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <FormGroup>
                      <Label>{t('FIRST_NAME')}</Label>
                      <p><strong>{first_name}</strong></p>
                    </FormGroup>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <FormGroup>
                      <Label>{t('LAST_NAME')}</Label>
                      <p><strong>{last_name}</strong></p>
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <FormGroup>
                      <Label>{t('COMMON:EMAIL')}</Label>
                      <p><strong>{email}</strong></p>
                    </FormGroup>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <FormGroup>
                      <Label>{t('ROLE')}</Label>
                      <p><strong>{role}</strong></p>
                    </FormGroup>
                  </div>
                </div>
              </Form>
            </CardBody>
          </Card>
        </section>

        <p className="text-center email-support">
          <small>
            {t('COMMON:SUPPORT')}: {getMailtoAnchor(EMAIL, <b>{EMAIL}</b>)}
          </small>
        </p>
        <Footer />
      </div>

      <BurgerMenu />
    </div>
  );
}

export default Info;
