import axios from 'axios'
import qs from 'qs'

export const list = async (token, params = {}) => {
  const {
    offset = 0,
    order = 'DESC',
    sort,
    limit = process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT,
    search,
    actorId,
    type,
    matched,
    activityUserId,
  } = params
  const showingRoutes = activityUserId || ['carry', 'send'].includes(type)
  const queryString = {
    offset,
    order,
    sort: sort || (showingRoutes ? 'createDate' : 'pickupDate'),
    limit,
    search,
    actorId,
    type,
    matched,
    activityUserId,
  }
  const endpoint = showingRoutes ? 'routes' : 'travels'

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/${endpoint}?${qs.stringify(queryString)}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const get = async (token, id, params = {}) => {
  const { type } = params
  const showingRoutes = ['carry', 'send'].includes(type)
  const endpoint = showingRoutes ? 'routes' : 'travels'

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/${endpoint}/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const setClosed = async (token, id) => {

  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/travels/${id}/close`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const setCanceled = async (token, id) => {

  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/travels/${id}/cancel`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}
