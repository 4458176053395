/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, Form, FormGroup, Label, Badge } from 'reactstrap'
import moment from 'moment'
import { formatProfileType } from '../../lib/helpers/profiles'

function ProfileInfo({ profile }) {
  const [t] = useTranslation('CONTACT_DETAIL')
  const { type = 'private', email = '', firstName = '', lastName = '', birthDate = '', birthPlace = '', taxCode = '', cellPhone = '', job = '' } = profile || {}
  const { city = '', cap = '', street = '', country = '' } = profile.address || {}
  const { companyName = '', vatNumber = '', sdiCode = '' } = profile.vatInfo || {}
  const { reaNumber = '', licenseNumber = '', insuranceNumber = '', insuranceCompany = '', insuranceExpiration = '', fleetType = '', partners = false } = profile.professionalInfo || {}

  return (
    <Card>
      <CardBody>
        <Form className="profile-form">
          <div className="row m-b-50">
            <div className="order-2 order-sm-2 order-md-1 col-sm-12 col-md-6">
              <FormGroup>
                <Label>{t('USER_TYPE')}</Label>
                <Badge className={`siwego-${type} m-l-20`}>{formatProfileType(type)}</Badge>
              </FormGroup>
            </div>
            <div className="order-1 order-sm-1 order-md-2 col-sm-12 col-md-6 edit-element">
              <a className="btn btn-conclude" href={process.env.REACT_APP_AUTH0_LINK} target="_blank">
                {t('BTN_DELETE_USER')}
              </a>
              <a className="btn btn-cancel" href={process.env.REACT_APP_AUTH0_LINK} target="_blank">
                {t('BTN_CHANGE_PSW')}
              </a>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FormGroup>
                <Label>{t('NAME_SURNAME')}</Label>
                <p>
                  <strong>{`${firstName} ${lastName}` || '-'}</strong>
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FormGroup>
                <Label>{t('BIRTH_DATE')}</Label>
                <p>
                  <strong>{moment(birthDate).format('DD/MM/YYYY') || '-'}</strong>
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FormGroup>
                <Label>{t('BIRTH_PLACE')}</Label>
                <p>
                  <strong>{birthPlace || '-'}</strong>
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FormGroup>
                <Label>{t('PHONE_NUMBER')}</Label>
                <p>
                  <strong>{cellPhone || '-'}</strong>
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FormGroup>
                <Label>{t('BUSINESS_NAME')}</Label>
                <p>
                  <strong>{companyName || '-'}</strong>
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FormGroup>
                <Label>{t('FISCAL_CODE')}</Label>
                <p>
                  <strong>{taxCode || '-'}</strong>
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FormGroup>
                <Label>{t('VAT_NUMBER')}</Label>
                <p>
                  <strong>{vatNumber || '-'}</strong>
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FormGroup>
                <Label>{t('CITY')}</Label>
                <p>
                  <strong>{city || '-'}</strong>
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FormGroup>
                <Label>{t('CAP')}</Label>
                <p>
                  <strong>{cap || '-'}</strong>
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FormGroup>
                <Label>{t('ADRESS')}</Label>
                <p>
                  <strong>{street || '-'}</strong>
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FormGroup>
                <Label>{t('NATION')}</Label>
                <p>
                  <strong>{country || '-'}</strong>
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FormGroup>
                <Label>{t('COMMON:EMAIL')}</Label>
                <p>
                  <strong>{email || '-'}</strong>
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FormGroup>
                <Label>{t('JOB')}</Label>
                <p>
                  <strong>{job || '-'}</strong>
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FormGroup>
                <Label>{t('SDI_CODE')}</Label>
                <p>
                  <strong>{sdiCode || '-'}</strong>
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FormGroup>
                <Label>{t('TRANSPORT_AUTHORIZATION')}</Label>
                <p>
                  <strong>{licenseNumber || '-'}</strong>
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FormGroup>
                <Label>{t('REA_CODE')}</Label>
                <p>
                  <strong>{reaNumber || '-'}</strong>
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FormGroup>
                <Label>{t('INSURANCE_CODE')}</Label>
                <p>
                  <strong>{insuranceNumber || '-'}</strong>
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FormGroup>
                <Label>{t('INSURANCE_COMPANY')}</Label>
                <p>
                  <strong>{insuranceCompany || '-'}</strong>
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FormGroup>
                <Label>{t('INSURANCE_DATE')}</Label>
                <p>
                  <strong>{moment(insuranceExpiration).isValid() ? moment(insuranceExpiration).format('DD/MM/YYYY') : '-'}</strong>
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FormGroup>
                <Label>{t('VEHICLE_NUMBER')}</Label>
                <p>
                  <strong>{fleetType || '-'}</strong>
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FormGroup>
                <Label>{t('MEMBERS')}</Label>
                <p>
                  <strong>{partners ? 'SI' : 'NO'}</strong>
                </p>
              </FormGroup>
            </div>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}

export default ProfileInfo
