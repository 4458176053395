import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import Menu from '../../commons/Menu';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import { ReactComponent as IconPrivacy } from '../../images/icn_cookie.svg';
import './PrivacyPolicy.scss';


function PrivacyPolicy({match}) {
    const [t] = useTranslation('PRIVACY_POLICY');

  return (
      <div className="App">
      <Menu match={match} />
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('TITLE')}
            icon={<IconPrivacy fill='#2F5D5D' />}
          />
        </section>

        <hr />

        <Card>
            <CardBody dangerouslySetInnerHTML={{__html: t('PRIVACY_POLICY_TEXT')}}>
            </CardBody>
          </Card>

        <Footer />
      </div>
    </div>
  
  )
}

export default PrivacyPolicy;
