import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAlert } from 'react-alert'
import { useQueryParams, StringParam, NumberParam } from 'use-query-params'
import { list } from '../../lib/fetch/profiles'
import TablePresentational from './ProfilesPresentational'
import { useAuth } from '../../commons/Auth'
import { formatProfileType, getCompanyName } from '../../lib/helpers/profiles'

function List(props) {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const { t } = useTranslation('FORGOT')
  const alert = useAlert()
  const [auth = {}] = useAuth();
  const { token = '' } = auth;

  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
    order: StringParam,
    sort: StringParam,
    limit: NumberParam,
    search: StringParam,
    emailVerified: StringParam
  })

  const fetchProfiles = async query => {
    setLoading(true)
    const { error, data, headers } = await list(token, query)

    if (error) {
      setData([])
      setTotal(0)
      setLoading(false)
      alert.error(t('COMMON:GENERIC_ERROR'))
      return
    }

    setData(data)
    setTotal(Number(headers['x-total-count']))
    setLoading(false)
  }

  useEffect(() => {
    fetchProfiles(query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const fetchCsvData = async () => {
    const { error, data = [] } = await list(token, { limit: 0 })

    if (error) {
      setData([])
      setTotal(0)
      alert.error(t('COMMON:GENERIC_ERROR'))
      return
    }

    const headers = [
      { label: 'Nome Utente', key: 'user' },
      { label: 'Ragione sociale', key: 'company' },
      { label: 'Email', key: 'email' },
      { label: 'Email Verificata', key: 'emailConfirmed' },
      { label: 'Città di residenza', key: 'city' },
      { label: 'Tipologia di utente', key: 'type' },
    ]

    const modifiedData = data.reduce((acc, row) => {
      const { email = '', address = {}, type = '', firstName = '', lastName = '', emailConfirmed = false } = row || {}
      const { city = '' } = address || {}
      const builtRow = {
        user: `${firstName} ${lastName}`,
        company: getCompanyName(row),
        email,
        emailConfirmed: emailConfirmed ? 'Sì' : 'No',
        city,
        type: formatProfileType(type),
      }
      return [...acc, builtRow]
    }, [])

    return { headers, data: modifiedData }
  }

  return (
    <TablePresentational
      data={data}
      total={total}
      query={query}
      onFilter={setQuery}
      isLoading={isLoading}
      fetchCsvData={fetchCsvData}
      {...props}
    />
  )
}

export default List
