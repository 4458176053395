import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { DataTable, Field, FieldButton, DateTimeField} from '../../commons/DataTable';

function DealsTable({ history, data, total = 0, query = {}, onFilter, isLoading }) {
  const [t] = useTranslation('COMMON');
  const { offset } = query;

  return (
    <DataTable
      offset={offset}
      total={total}
      data={data}
      isLoading={isLoading}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      onFilter={onFilter}
    >
      <Field title="Destinatario" source="recipient" />
      <Field title="Descrizione" source="invoice" />
      <Field title="Numero Fattura" source="number" />
      <DateTimeField title="Data Fattura" format="DD/MM/YYYY" source="date" className="close-date" />
      <FieldButton title="Download" source="number"/>
    </DataTable>
  );
}

export default withRouter(DealsTable);
