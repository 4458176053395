import React from 'react'
import { Button } from 'reactstrap'

function FilterVerifiedButton({onFilter, query = {}}) {
  const filtered = query.emailVerified === 'false';

  return (
    <Button
      className="btn-plus filter-email"
      onClick={() => onFilter({
        ...query,
        emailVerified: filtered ? '' : 'false',
      })}
      type="button"
    >
      Mostra { filtered ? 'tutto' : 'email non verificate' }
    </Button>
  )
}

export default FilterVerifiedButton




