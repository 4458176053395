import React, { useRef } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from "moment";
import it from "date-fns/locale/it";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from 'reactstrap'

function YearInput({ name = "", className = "", value, onChange }) {
  const pickerRef = useRef(null);

  const DatepickerInput = (props) => {
    return <Button {...props}>{props.value}</Button>
  };

  return (
    <DatePicker
      ref={pickerRef}
      showYearPicker
      selected={value ? new Date(value) : new Date()}
      onChange={(date) =>
        onChange({ year: date ? moment(date).format("YYYY") : undefined })
      }
      minDate={new Date("01-01-2019")}
      maxDate={new Date()}
      dateFormat="yyyy"
      locale={it}
      yearItemNumber={8}
      customInput={<DatepickerInput />}
    />
  );
}

YearInput.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default YearInput;
