import axios from 'axios';

const forgot = async (email) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_FORGOT}?appId=${process.env.REACT_APP_ID}`,
      data: {
        email,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export default forgot;
