import React from 'react';
import signup from '../../lib/fetch/signup';
import SignupPresentational from './SignupPresentational';

function Signup(props) {
  const onSignup = async ({ email = '', password = '' }) => {
    const { error } = await signup(email, password);

    if (error) {
      //handle error TODO
      return;
    }

    //TODO
  }

  return (<SignupPresentational onSignup={onSignup} {...props} />);
}

export default Signup;
