import React from 'react'
import Proptypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import './ProcessStep.scss'
import { ALL_STEPS } from './steps'

function ProcessStep({ stage }) {
  const [t] = useTranslation('DELIVERY')

  return (
    <div>
      <div className="root">
        <div className="container-processbar">
          <ul className="progressbar">
            {ALL_STEPS.map((step, index) => (
              <li key={index} className={step.value === stage ? 'active' : ''}>
                {t(`${step.name.toUpperCase()}`)}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

ProcessStep.propTypes = {
  stage: Proptypes.string,
}

export default ProcessStep
