import './App.scss'
import React from 'react'
import { HashRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import { AuthProvider, PrivateRoute, PublicRoute, RoutesWrapper } from './commons/Auth'
import Login from './pages/Login'
import Forgot from './pages/Forgot'
import Reset from './pages/Reset'
import Signup from './pages/Signup'
import Verification from './pages/Verification'
import Info from './pages/Info'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Terms from './pages/Terms'
import { I18nextProvider } from 'react-i18next'
import { QueryParamProvider } from 'use-query-params'
import { Provider as AlertProvider, positions } from 'react-alert'
import i18next from 'i18next'
import moment from 'moment'
import 'moment/locale/it'
import AlertTemplate from './commons/AlertTemplate'
import { LoadingProvider } from './commons/Loading'
import Dashboard from './pages/Dashboard'
import Profiles from './pages/Profiles'
import ProfileDetail from './pages/ProfileDetail'
import Deliveries from './pages/Deliveries'
import Alert from './pages/Alert'
import Invoices from './pages/Invoices'
import PageNotFound from './pages/PageNotFound'
import translations from './translations'
import DeliveryDetail from './pages/DeliveryDetail'
import Partners from './pages/Partners'
import PartnerDetail from './pages/PartnerDetail'

i18next.init({
  resources: translations,
  lng: 'it',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
})

moment.locale('it')

function App() {
  return (
    <div className="App">
      <I18nextProvider i18n={i18next}>
        <AlertProvider template={AlertTemplate} position={positions.TOP_RIGHT} timeout={5000}>
          <CookiesProvider>
            <AuthProvider>
              <LoadingProvider>
                <Router>
                  <QueryParamProvider ReactRouterRoute={Route}>
                    <Switch>
                      <RoutesWrapper publicRedirect="/" privateRedirect="/dashboard">
                        <PublicRoute exact path="/" component={Login} />
                        <PublicRoute path="/signup" component={Signup} />
                        <PublicRoute path="/forgot" component={Forgot} />
                        <PublicRoute path="/reset" component={Reset} />
                        <PublicRoute path="/verification" component={Verification} />
                        <Redirect path="/" exact to="/dashboard" />
                        <PrivateRoute path="/dashboard" component={Dashboard} />
                        <PrivateRoute exact path="/profiles" component={Profiles} />
                        <PrivateRoute path="/profiles/:id" component={ProfileDetail} />
                        <PrivateRoute exact path="/partners" component={Partners} />
                        <PrivateRoute path="/partners/:id" component={PartnerDetail} />
                        <PrivateRoute exact path="/deliveries" component={Deliveries} />
                        <PrivateRoute path="/deliveries/:id" component={DeliveryDetail} />
                        <PrivateRoute path="/alerts" component={Alert} />
                        <PrivateRoute exact path="/invoices" component={Invoices} />
                        <PrivateRoute path="/info" component={Info} />
                        <PrivateRoute path="/privacypolicy" component={PrivacyPolicy} />
                        <PrivateRoute path="/terms" component={Terms} />
                        <Route component={PageNotFound} />
                      </RoutesWrapper>
                    </Switch>
                  </QueryParamProvider>
                </Router>
              </LoadingProvider>
            </AuthProvider>
          </CookiesProvider>
        </AlertProvider>
      </I18nextProvider>
    </div>
  )
}

export default App
