import './DeliveryDetail.scss'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import Menu from '../../commons/Menu'
import BurgerMenu from '../../commons/BurgerMenu'
import Header from '../../commons/Header'
import Footer from '../../commons/Footer'
import { ReactComponent as IconDelivery } from '../../images/icn_spedizioni.svg'
import { Card, CardBody, Button, CardHeader } from 'reactstrap'
import backIcon from '../../images/icn-back.svg'
import ProcessStep from '../../components/ProcessStep'
import ModalConfirm from '../../components/ModalConfirm'
import { ReactComponent as IconRight } from '../../images/icn_arrow_right.svg'
import { ReactComponent as AlertIcon } from '../../images/icn_alert.svg'
import Spinner from '../../commons/Spinner'
import { useAlert } from 'react-alert'
import moment from 'moment'
import { setCanceled, setClosed, get } from '../../lib/fetch/deliveries'
import { useAuth } from '../../commons/Auth'
import { StringParam, useQueryParams } from 'use-query-params'
import { formatName } from '../../lib/helpers/profiles'
import { ALL_STEPS } from '../../components/ProcessStep/steps'

function getAlertText(type) {
  switch (type) {
    case 'paymentExceeding':
      return (
        <>
          È stata effettuata una transazione con costo superiore a <strong>200,00 €</strong>
        </>
      )
    case 'adminTravelClose':
      return (
        <>
          Spedizione conclusa da Amministrazione <strong>SiWeGO</strong>
        </>
      )
    case 'adminTravelCancel':
      return (
        <>
          Spedizione cancellata da Amministrazione <strong>SiWeGO</strong>
        </>
      )
    case 'monthlyTransactions':
      return (
        <>
          Un utente ha superato <strong>10</strong> transazioni mensili
        </>
      )
    case 'yearlyTotalTransactions':
      return (
        <>
          Un utente ha superato <strong>2000 €</strong> in transazioni annuali
        </>
      )
    default:
      return null
  }
}

function DeliveryDetail({ history, match }) {
  const [travel, setTravel] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [t] = useTranslation()
  const { params: { id } = {} } = match
  const alert = useAlert()
  const [auth = {}] = useAuth()
  const { token = '' } = auth


  const [query] = useQueryParams({ type: StringParam, alertType: StringParam })
  const { type, alertType } = query
  const showingRoute = ['carry', 'send'].includes(type)
  let title = t('COMMON:DELIVERY')

  if (type === 'carry') title = 'Trasporto'
  else if (type === 'send') title = 'Spedizione'

  const fetchDelivery = async id => {
    const { error, data } = await get(token, id, { type })

    if (error) {
      setTravel({})
      alert.error(t('COMMON:GENERIC_ERROR'))
      setIsLoading(false)
      return
    }

    setTravel(data)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchDelivery(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cancelTravel = async () => {
    setIsLoading(true)
    const { error } = await setCanceled(token, id)

    if (error) {
      setTravel({})
      alert.error(t('COMMON:GENERIC_ERROR'))
      setIsLoading(false)
      return
    }

    fetchDelivery(id)
  }

  const closeTravel = async () => {
    setIsLoading(true)
    const { error } = await setClosed(token, id)

    if (error) {
      setTravel({})
      alert.error(t('COMMON:GENERIC_ERROR'))
      setIsLoading(false)
      return
    }

    fetchDelivery(id)
  }

  const { price, travelStatus, pickupDate, dropoffDate, createDate } = travel
  const {
    from,
    to,
    packageSize,
    maximumPrice,
    transportType,
    date,
    arrivalDate,
    professionalCarrier,
    gpsEnabled
  } = travel.route || travel || {}
  const { pickUpSecret, dropOffSecret } = travel.security || {}

  const owner = travel.owner || (travel.route && travel.route.owner) || {}
  const sender = travel.senderProfile || {}
  const transporter = travel.transporterProfile || {}

  return (
    <div className="App">
      <Menu match={match} />
      <BurgerMenu />

      {isLoading ? (
        <div className="u-center-center">
          <Spinner />
        </div>
      ) : (
          <div className="corpo">
            <section>
              <Header title={title} icon={<IconDelivery fill="#2F5D5D" />} />

              <div className="row m-b-30">
                <div className="col-sm-12 col-md-4">
                  <Button className="btn-plus" onClick={() => history.goBack()}>
                    <img src={backIcon} alt="" /> {t('COMMON:BTN_BACK')}
                  </Button>
                </div>
                {!showingRoute && <div className="col-sm-12 col-md-8 edit-element resposive-margin">
                  <ModalConfirm title={t('DELIVERY:BTN_CONCLUDE')} text={t('DELIVERY:CONCLUDE_DESCRIPTION')} onConfirm={closeTravel}>
                    <Button className="btn-conclude">{t('DELIVERY:BTN_CONCLUDE')}</Button>
                  </ModalConfirm>
                  <ModalConfirm title={t('DELIVERY:BTN_CANCEL')} text={t('DELIVERY:CANCEL_DESCRIPTION')} onConfirm={cancelTravel}>
                    <Button className="btn-cancel">{t('DELIVERY:BTN_CANCEL')}</Button>
                  </ModalConfirm>
                </div>}
              </div>

            {alertType && (
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <Card className="sales-card">
                    <CardBody>
                      <div className="alert-body">
                        <span className="alert-icon">
                          <AlertIcon fill="#2F5D5D" />
                        </span>
                        <span className="alert-text">
                          {getAlertText(alertType)}
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>)}

              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <Card className="sales-card">
                    <CardHeader>
                      <h3>
                        <span>Creato da:</span> {formatName(owner)}<span>, {price}</span>
                      </h3>
                      <p>
                        <span>{from}</span> <IconRight /> <span>{to}</span>
                      </p>

                      {!showingRoute && <ProcessStep stage={travelStatus} onSave={() => { }} />}
                      {!travelStatus && <ProcessStep stage={ALL_STEPS[0].value} onSave={() => { }} />}
                    </CardHeader>
                    <CardBody>
                      <div className="cont-info">
                        <ul className="dotted-list">

                          <li className="dotted-list-item">
                            <span className="description">{from || '-'}</span>
                            <span className="definition">{t('DELIVERY:DEPARTURE')}:</span>
                          </li>

                          <li className="dotted-list-item">
                            <span className="description">{to || '-'}</span>
                            <span className="definition">{t('DELIVERY:ARRIVAL')}:</span>
                          </li>

                          <li className="dotted-list-item">
                            <span className="description">
                              {moment(date).isValid() ? moment(date).format('DD/MM/YYYY') : '-'}
                            </span>
                            <span className="definition">
                              {t('DELIVERY:ROUTE_DATE')}:
                          </span>
                          </li>

                          <li className="dotted-list-item">
                            <span className="description">
                              {moment(arrivalDate).isValid() ? moment(arrivalDate).format('DD/MM/YYYY') : '-'}
                            </span>
                            <span className="definition">
                              {t('DELIVERY:ROUTE_ARRIVAL_DATE')}:
                          </span>
                          </li>

                          {(!showingRoute || type === 'send') && <li className="dotted-list-item">
                            <span className="description">{maximumPrice || '-'}</span>
                            <span className="definition">{t('DELIVERY:MAXIMUM_PRICE')}:</span>
                          </li>}

                          <li className="dotted-list-item">
                            <span className="description">
                              {packageSize ? t(`DELIVERY:PACKAGE_SIZES_${packageSize.toUpperCase()}`) : '-'}
                            </span>
                            <span className="definition">{t('DELIVERY:PACK_SIZE')}:</span>
                          </li>

                          <li className="dotted-list-item">
                            <span className="description">
                              {transportType ? t(`DELIVERY:TRANSPORT_TYPES_${transportType.toUpperCase()}`) : '-'}
                            </span>
                            <span className="definition">{t('DELIVERY:TRANSPORT')}:</span>
                          </li>

                          <li className="dotted-list-item">
                            <span className="description">{t(`COMMON:${professionalCarrier ? 'YES' : 'NO'}`)}</span>
                            <span className="definition">{t('DELIVERY:PROFESSIONAL_CARRIER')}:</span>
                          </li>

                          <li className="dotted-list-item">
                            <span className="description">{t(`COMMON:${gpsEnabled ? 'YES' : 'NO'}`)}</span>
                            <span className="definition">{t('DELIVERY:SEND_GPS_TRACKING')}:</span>
                          </li>

                          <li className="dotted-list-item">
                            <span className="description">
                              {formatName(showingRoute ? owner : sender)}
                            </span>
                            <span className="definition">
                              {showingRoute && type === 'carry' ? 'Trasportatore' : 'Mittente'}:
                          </span>
                          </li>

                          {!showingRoute && <li className="dotted-list-item">
                            <span className="description">{formatName(transporter)}</span>
                            <span className="definition">Trasportatore:</span>
                          </li>}

                          {showingRoute && <li className="dotted-list-item">
                            <span className="description">
                              {moment(createDate).isValid() ? moment(createDate).format('DD/MM/YYYY') : '-'}
                            </span>
                            <span className="definition">
                              Data di creazione:
                          </span>
                          </li>}

                          {!showingRoute && <li className="dotted-list-item">
                            <span className="description">
                              {moment(pickupDate).isValid() ? moment(pickupDate).format('DD/MM/YYYY') : '-'}
                            </span>
                            <span className="definition">
                              {t('DELIVERY:PICKUP_DATE')}:
                          </span>
                          </li>}

                          {!showingRoute && <li className="dotted-list-item">
                            <span className="description">
                              {moment(dropoffDate).isValid() ? moment(dropoffDate).format('DD/MM/YYYY') : '-'}
                            </span>
                            <span className="definition">{t('DELIVERY:DROPOFF_DATE')}:</span>
                          </li>}

                          {!showingRoute && <li className="dotted-list-item">
                            <span className="description">{price || '-'}</span>
                            <span className="definition">{t('DELIVERY:FINAL_PRICE')}:</span>
                          </li>}

                          {!showingRoute && <li className="dotted-list-item">
                            <span className="description">{pickUpSecret || '-'}</span>
                            <span className="definition">{t('DELIVERY:RETREAT_CODE')}:</span>
                          </li>}

                          {!showingRoute && <li className="dotted-list-item">
                            <span className="description">{dropOffSecret || '-'}</span>
                            <span className="definition">{t('DELIVERY:DELIVERY_CODE')}:</span>
                          </li>}
                        </ul>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        )}
    </div>
  )
}

export default withRouter(DeliveryDetail)
