import React from 'react';
import PropTypes from 'prop-types';

import './Kpi.scss';

function Kpi(props) {
  return (
    <div className="kpi-box">
      <h5>
        {props.title}
      </h5>
      <hr />
      <p>
        {props.value}<br />
        <i>{props.unitLabel}</i>
      </p>
    </div>
  );
}

Kpi.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  unitLabel: PropTypes.string.isRequired,
};

export default Kpi;
