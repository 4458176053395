import React from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import Menu from '../../commons/Menu'
import BurgerMenu from '../../commons/BurgerMenu'
import Header from '../../commons/Header'
import Footer from '../../commons/Footer'
import { ReactComponent as IconContacts } from '../../images/icn_utenti.svg'
import SearchInput from '../../commons/SearchInput'
import CSVDownload from '../../commons/CSVDownload'
import Table from './Table'
import FilterVerifiedButton from './FilterVerifiedButton'
import './Profiles.scss'

function ProfilesPresentational(props) {
  const [t] = useTranslation('CONTACTS')

  const { data, total = 0, query, onFilter, isLoading, fetchCsvData } = props

  return (
    <div className="App">
      <Menu />
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header title={t('COMMON:USERS')} icon={<IconContacts fill="#2F5D5D" />} />

          <div className="row search-add-cont">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <SearchInput onFilter={onFilter} query={query} />
            </div>

            <div className="col-sm-12 col-md-3 col-lg-3">
              <FilterVerifiedButton onFilter={onFilter} query={query}/>
            </div>

            <div className="col-sm-12 col-md-3 col-lg-3">
              <CSVDownload
                fileName="ProfiliUtente"
                isLoading={isLoading}
                fetchCsvData={fetchCsvData}
              />
            </div>
          </div>

          <hr />

          <Card>
            <CardBody>
              <Table
                data={data}
                total={total}
                query={query}
                onFilter={onFilter}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </section>
        <Footer />
      </div>
    </div>
  )
}

export default withRouter(ProfilesPresentational)
