import axios from 'axios'
import qs from 'qs'

export const list = async (token, params = {}) => {
  const { offset = 0, order = 'ASC', sort = 'createdAt', limit = process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT, search } = params
  const queryString = { offset, order, sort, limit, search }

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/alerts?${qs.stringify(queryString)}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}
