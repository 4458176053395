import React from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { DataTable, Field } from '../../commons/DataTable'
import ProfileFieldType from '../../commons/DataTable/ProfileFieldType'
import BooleanField from '../../commons/DataTable/BooleanField'

function ProfilesTable({ history, data, total = 0, query = {}, onFilter, isLoading }) {
  const [t] = useTranslation('COMMON')
  const { offset } = query

  return (
    <DataTable
      offset={offset}
      total={total}
      data={data}
      isLoading={isLoading}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      query={query}
      onFilter={onFilter}
      onRowClick={({ id } = {}) => history.push(`/profiles/${id}`)}
    >
      <Field title="Nome Utente" source={['lastName', 'firstName']} sort="lastName" />
      <Field title="Ragione sociale" source="vatInfo.companyName" sort="companyName" />
      <Field title="Email" source="email" sort="email"/>
      <BooleanField title="Email Verificata" source="emailConfirmed"/>
      <Field title="Città di residenza" source="address.city" sort="city"/>
      <ProfileFieldType title="Tipologia di utente" source="type" sort="type"/>
    </DataTable>
  )
}

export default withRouter(ProfilesTable)
