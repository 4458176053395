import React from 'react'
import { Card, CardBody } from 'reactstrap'
import Menu from '../../commons/Menu'
import Header from '../../commons/Header'
import Footer from '../../commons/Footer'
import BurgerMenu from '../../commons/BurgerMenu'
import DeliveriesTable from './DeliveriesTable'
import { ReactComponent as IconDelivery } from '../../images/icn_spedizioni.svg'
import SearchInput from '../../commons/SearchInput'
import CSVDownload from '../../commons/CSVDownload'

function DeliveriesPresentational(props) {
  const { data, total = 0, query = {}, onFilter, isLoading = false, fetchCsvData } = props
  const { matched, type } = query;
  let title = 'Trasporti e spedizioni con match'

  if (type === 'carry') title = 'Trasporti'
  else if (type === 'send') title = 'Spedizioni'

  if (['carry', 'send'].includes(type)) {
    title += matched === 'false' ? ' senza match' : ' con e senza match'
  }

  return (
    <div className="App">
      <Menu />
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header title={title} icon={<IconDelivery fill="#2F5D5D" />} />

          <div className="row search-add-cont">
            <div className="col-sm-12 col-md-6 col-lg-8">
              <SearchInput onFilter={onFilter} query={query} />
            </div>

            {data && data.length ? <div className="col-sm-12 col-md-6 col-lg-4">
              <CSVDownload fileName={title} isLoading={isLoading} fetchCsvData={fetchCsvData}/>
            </div> : ''}
          </div>

          <hr />

          <Card>
            <CardBody>
              <DeliveriesTable
                data={data}
                total={total}
                query={query}
                onFilter={onFilter}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </section>
        <Footer />
      </div>
    </div>
  )
}

export default DeliveriesPresentational
