import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useAlert } from 'react-alert'
import { useQueryParams, NumberParam, StringParam } from 'use-query-params'
import { list } from '../../lib/fetch/deliveries'
import DeliveriesTable from '../Deliveries/DeliveriesTable'
import { useAuth } from '../../commons/Auth'

function ProfileActivities({ profile }) {

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [t] = useTranslation()
  const alert = useAlert()
  const [auth = {}] = useAuth();
  const { token = '' } = auth;

  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
    limit: NumberParam,
    order: StringParam,
    sort: StringParam,
  })

  useEffect(() => {
    const fetchDeliveries = async () => {
      setLoading(true)
      const { error, data, headers } = await list(token, { ...query, activityUserId: profile.id })

      if (error) {
        setLoading(false)
        setData([])
        setTotal(0)
        alert.error(t('COMMON:GENERIC_ERROR'))
        return
      }

      setLoading(false)
      setData(data)
      setTotal(Number(headers['x-total-count']))
    }
    if (profile.id) {
      fetchDeliveries()
    }
  }, [profile.id, query])

  return (
    <Card>
      <CardBody>
        <DeliveriesTable
          data={data}
          total={total}
          query={query}
          onFilter={setQuery}
          isLoading={loading}
          routes
        />
      </CardBody>
    </Card>
  )
}

export default ProfileActivities
