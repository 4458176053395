import React from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { DataTable, Field, CurrencyField, DateTimeField, BooleanField } from '../../commons/DataTable'

function DeliveriesTable({ history, data, total = 0, query = {}, onFilter, isLoading, routes }) {
  const [t] = useTranslation('COMMON')
  const { offset, type } = query
  const showingRoutes = routes || ['carry', 'send'].includes(type)
  const userType = `${showingRoutes ? '' : 'route.'}owner`
  const fromSource = `${showingRoutes ? '' : 'route.'}from`
  const toSource = `${showingRoutes ? '' : 'route.'}to`
  const priceName = `Prezzo${showingRoutes ? ' massimo' : ' concordato'}`
  const priceSource = showingRoutes ? 'maximumPrice' : 'price'
  const dateName = `Data ${showingRoutes ? 'creazione' : 'ritiro'}`
  const dateSource = showingRoutes ? 'createDate' : 'pickupDate'

  return (
    <DataTable
      offset={offset}
      total={total}
      data={data}
      isLoading={isLoading}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      query={query}
      onFilter={onFilter}
      onRowClick={({ id, type: routeType, travels = [] } = {}) => {
        if (!travels || !travels.length) {
          // Se la lista è di route, la route non ha match perché non ci sono travel associati. Vai al dettaglio della route.
          // Se invece non c'è né type né travel, allora la lista è di travel. Vai al dettaglio del travel.
          const pushType = type || routeType
          return history.push(`/deliveries/${id}${pushType ? `?type=${pushType}` : ''}`)
        }
        // C'è almeno un travel, quindi la lista è di route.
        // L'utente può aver fatto un solo match con la route. Dall'API arriverà solo il mio travel.
        // Vai al dettaglio del travel.
        const [{ id: travelId }] = travels
        history.push(`/deliveries/${travelId}`)
      }}
    >
      <Field
        title="Creato da"
        source={[`${userType}.lastName`, `${userType}.firstName`]}
        sort={'owner.lastName'}
      />
      <Field
        title="Ragione sociale"
        source={`${userType}.vatInfo.companyName`}
        sort="vatInfo.companyName"
      />
      <Field
        title="Luogo Partenza"
        source={fromSource}
        sort={fromSource}
      />
      <Field
        title="Luogo Arrivo"
        source={toSource}
        sort={toSource}
      />
      <CurrencyField
        title={priceName}
        source={priceSource}
        sort={priceSource}
      />
      <DateTimeField
        title={dateName}
        format="DD/MM/YYYY"
        source={dateSource}
        sort={dateSource}
        className="close-date"
      />
      <BooleanField title="Archiviato" source='softDeleted' sort="softDeleted"/>
    </DataTable>
  )
}

export default withRouter(DeliveriesTable)
