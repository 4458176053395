import './ProfileDetail.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter, Link, Route, Switch } from 'react-router-dom'
import classnames from 'classnames'
import { Button, Nav, NavItem } from 'reactstrap'
import Menu from '../../commons/Menu'
import BurgerMenu from '../../commons/BurgerMenu'
import Header from '../../commons/Header'
import Footer from '../../commons/Footer'
import { ReactComponent as IconContacts } from '../../images/icn_utenti.svg'
import backIcon from '../../images/icn-back.svg'
import ProfileInfo from './ProfileInfo'
import ProfileActivities from './ProfileActivities'
import ProfileTransactions from './ProfileTransactions'
import { get } from '../../lib/fetch/profiles'
import Spinner from '../../commons/Spinner'
import { useAlert } from 'react-alert'
import {useAuth} from '../../commons/Auth'

function ProfileDetail({ match, history, location }) {
  const [isLoading, setIsLoading] = useState(true)
  const [profile, setProfile] = useState({})
  const [t] = useTranslation()
  const alert = useAlert()
  const { params: { id } = {} } = match
  const [auth = {}] = useAuth();
  const { token = '' } = auth;

  const { pathname = '' } = location
  const tab = pathname.split('/').pop()

  const fetchProfile = async id => {
    const { error, data } = await get(token, id)

    if (error) {
      setProfile({})
      alert.error(t('COMMON:GENERIC_ERROR'))
      return
    }

    setProfile(data)
    setIsLoading(false)
    history.push(`${match.url}/info`)
  }

  useEffect(() => {
    fetchProfile(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { firstName = '', lastName = '' } = profile

  return (
    <div className="App">
      <Menu />
      <BurgerMenu />

      {isLoading ? (
        <div className="u-center-center">
          <Spinner />
        </div>
      ) : (
          <div className="corpo">
            <section>
              <Header title={t('COMMON:USERS')} icon={<IconContacts fill="#2F5D5D" />} />
              <hr />
              <div className="title-header">
                <div className="row m-b-30">
                  <div className="col-sm-12 col-md-5 col-lg-6">
                    <h4>
                      <Button className="btn-plus" onClick={() => history.push('/profiles')}>
                        <img src={backIcon} alt="" /> {t('COMMON:BTN_BACK')}
                      </Button>
                      <b>{`${firstName} ${lastName}`}</b>
                    </h4>
                  </div>
                </div>
              </div>

              <Nav tabs style={{ marginTop: '-30px' }}>
                <NavItem>
                  <Link to={`${match.url}/info`} className={classnames({ active: tab === 'info', 'nav-link': true })}>
                    {t('CONTACT_DETAIL:USER_DATA')}
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to={`${match.url}/activities`} className={classnames({ active: tab === 'activities', 'nav-link': true })}>
                    {t('CONTACT_DETAIL:ACTIVITY_LIST')}
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to={`${match.url}/transactions`} className={classnames({ active: tab === 'transactions', 'nav-link': true })}>
                    {t('CONTACT_DETAIL:TRANSACTIONS')}
                  </Link>
                </NavItem>
              </Nav>

              <Switch>
                <Route path={`${match.url}/info`} component={props => <ProfileInfo profile={profile} {...props} />} />
                <Route path={`${match.url}/activities`} component={props => <ProfileActivities profile={profile} {...props} />} />
                <Route path={`${match.url}/transactions`} component={props => <ProfileTransactions profile={profile} {...props} />} />
              </Switch>
            </section>
            <Footer />
          </div>
        )}
    </div>
  )
}

export default withRouter(ProfileDetail)
