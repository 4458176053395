/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {Card, CardBody, Form, FormGroup, Label, Input, Button} from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ModalConfirm from '../../commons/ModalConfirm';
import DateInput from '../../commons/DateInput';
import moment from 'moment';

function PartnerInfo({ onDelete = () => {}, onSave = () => {}, partner = {}, setPartner = () => {} } = {}) {
  const [t] = useTranslation('PARTNERS');
  const [username, setUsername] = useState(partner.username);
  const [expire, setExpire] = useState(partner.expire);

  return (
    <Card>
      <CardBody>
        <Form className="partner-form">
          <div className="row m-b-50">
            <div className="col-sm-12 col-md-12 edit-element">
              <Button type="button" className="btn btn-cancel" onClick={() => onSave({ username, expire })}>
                {t('COMMON:SAVE')}
              </Button>
              <ModalConfirm title={t('DELETE_MSG_1')} text={t('DELETE_MSG_2')} onConfirm={onDelete}>
                <Button type="button" className="btn btn-conclude">
                  {t('DELETE')}
                </Button>
              </ModalConfirm>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <FormGroup>
                <Label>{t('USERNAME_LABEL')}</Label>
                <Input
                  value={username}
                  onChange={({ target }) => setUsername(target.value)}
                />
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <FormGroup>
                <Label>{t('EXPIRATION')}</Label>
                <DateInput
                  value={expire}
                  onChange={newValue => setExpire(moment(newValue).format('YYYY-MM-DD'))}
                />
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <FormGroup>
                <Label>UserID</Label>
                <p>
                  <strong>{partner.userId || '-'}</strong>
                </p>
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <FormGroup>
                <Label>API Key</Label>
                <p>
                  <strong>{partner.key || '-'}</strong>
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 edit-element justify-start">
              <CopyToClipboard text={partner.key}>
                <Button type="button" className="btn btn-copy">
                  {t("COPY_TO_CLIPBOARD")}
                </Button>
              </CopyToClipboard>
            </div>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}

export default PartnerInfo
