import './PartnerDetail.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, Link, Route, Switch } from 'react-router-dom';
import classnames from 'classnames';
import { Button, Nav, NavItem } from 'reactstrap';
import Menu from '../../commons/Menu';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import { ReactComponent as IconCompany} from '../../images/icn_aziende.svg';
import backIcon from '../../images/icn-back.svg';
import PartnerInfo from './PartnerInfo';
import PartnerCalls from './PartnerCalls';
import { get, remove, save } from '../../lib/fetch/partners';
import Spinner from '../../commons/Spinner';
import { useAlert } from 'react-alert';

function PartnerDetail({ match, history, location }) {
  const [isLoading, setIsLoading] = useState(true);
  const [partner, setPartner] = useState({});
  const [t] = useTranslation('PARTNERS');
  const alert = useAlert();
  const { params: { id } = {} } = match;
  const { pathname = '' } = location;
  const tab = pathname.split('/').pop();

  const fetchPartner = async id => {
    const { error, data } = await get(id);

    if (error) {
      setPartner({});
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    // data.hitCounts = data.hitCounts && data.hitCounts.length
    //   ? data.hitCounts
    //   : [{ billingPeriod: '2020-12', hit: 1 }];

    setPartner(data);
    setIsLoading(false);
  }

  const onDelete = async () => {
    setIsLoading(true);
    const { error } = await remove(partner.userId);
    setIsLoading(false);

    if (error) alert.error(t('COMMON:GENERIC_ERROR'));
    else history.push('/partners');
  };

  const onSave = async ({ username, expire }) => {
    setIsLoading(true);
    const { error } = await save({
      ...partner,
      expire,
      username,
    });
    setIsLoading(false);

    if (error) alert.error(t('COMMON:GENERIC_ERROR'));
    else await fetchPartner(id);
  };

  useEffect(() => {
    fetchPartner(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <Menu />
      <BurgerMenu />

      {isLoading ? (
        <div className="u-center-center">
          <Spinner />
        </div>
      ) : (
          <div className="corpo">
            <section>
              <Header title={t('DETAIL')} icon={<IconCompany fill="#2F5D5D" />} />
              <hr />
              <div className="title-header">
                <div className="row m-b-30">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <h4>
                      <Button className="btn-plus" onClick={() => history.push('/partners')}>
                        <img src={backIcon} alt="" /> {t('COMMON:BTN_BACK')}
                      </Button>
                    </h4>
                  </div>
                </div>
              </div>

              <Nav tabs style={{ marginTop: '-30px' }}>
                <NavItem>
                  <Link to={`${match.url}/info`} className={classnames({ active: tab === 'info', 'nav-link': true })}>
                    {t('INFO')}
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to={`${match.url}/calls`} className={classnames({ active: tab === 'calls', 'nav-link': true })}>
                    {t('CALLS_COUNT')}
                  </Link>
                </NavItem>
              </Nav>

              <Switch>
                <Route
                  path={`${match.url}/info`}
                  component={props => <PartnerInfo
                    partner={partner}
                    setPartner={setPartner}
                    onDelete={onDelete}
                    onSave={onSave}
                    {...props}
                  />}
                />
                <Route
                  path={`${match.url}/calls`}
                  component={props => <PartnerCalls calls={partner.hitCounts} {...props} />}
                />
              </Switch>
            </section>
            <Footer />
          </div>
        )}
    </div>
  )
}

export default withRouter(PartnerDetail);
