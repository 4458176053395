import './Deliveries.scss'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAlert } from 'react-alert'
import { useQueryParams, NumberParam, StringParam } from 'use-query-params'
import DeliveriesPresentational from './DeliveriesPresentational'
import { list } from '../../lib/fetch/deliveries'
import moment from 'moment'
import { useAuth } from '../../commons/Auth'
import { getCompanyName } from '../../lib/helpers/profiles'

function Deliveries(props) {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [t] = useTranslation()
  const alert = useAlert()
  const [auth = {}] = useAuth();
  const { token = '' } = auth;

  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
    order: StringParam,
    sort: StringParam,
    limit: NumberParam,
    search: StringParam,
    type: StringParam,
    matched: StringParam,
  })
  const { type } = query;

  const fetchDeliveries = async query => {
    setIsLoading(true)
    const { error, data, headers } = await list(token, query)

    if (error) {
      setIsLoading(false)
      setData([])
      setTotal(0)
      alert.error(t('COMMON:GENERIC_ERROR'))
      return
    }

    setIsLoading(false)
    setData(data)
    setTotal(Number(headers['x-total-count']))
  }

  useEffect(() => {
    fetchDeliveries(query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const fetchCsvData = async () => {
    const { error, data = [] } = await list(token, { limit: 0, type })

    if (error) {
      setData([])
      setTotal(0)
      alert.error(t('COMMON:GENERIC_ERROR'))
      return
    }

    const showingRoutes = ['carry', 'send'].includes(type)
    const headers = [
      { label: 'Utente', key: 'user' },
      { label: 'Ragione sociale', key: 'company' },
      { label: 'Luogo Partenza', key: 'from' },
      { label: 'Luogo Arrivo', key: 'to' },
      { label: `Prezzo ${showingRoutes ? 'massimo' : 'concordato'}`, key: 'price' },
      { label: `Data ${showingRoutes ? 'creazione' : 'partenza'}`, key: 'date' },
    ]

    const modifiedData = data.reduce((acc, row) => {
      let builtRow = {}

      if (showingRoutes) {
        const { owner, from = '', to = '', maximumPrice = 0, createDate } = row
        const { firstName = '', lastName = '' } = owner || {}

        builtRow = {
          user: `${firstName} ${lastName}`,
          company: getCompanyName(owner),
          from,
          to,
          price: maximumPrice,
          date: moment(createDate).isValid() ? moment(createDate).format('DD/MM/YYYY') : ''
        }

        return [...acc, builtRow]

      } else {

        const { route = {}, price, pickupDate } = row
        const { owner, from = '', to = '' } = route || {}
        const { firstName = '', lastName = '' } = owner

        builtRow = {
          user: `${firstName} ${lastName}`,
          company: getCompanyName(owner),
          from,
          to,
          price,
          date: moment(pickupDate).isValid() ? moment(pickupDate).format('DD/MM/YYYY') : ''
        }
      }


      return [...acc, builtRow]
    }, [])

    return { headers, data: modifiedData }
  }

  return (
    <DeliveriesPresentational
      data={data}
      total={total}
      query={query}
      fetchCsvData={fetchCsvData}
      onFilter={setQuery}
      isLoading={isLoading}
      {...props}
    />
  )
}

export default Deliveries
