import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAlert } from 'react-alert'
import { useQueryParams, StringParam } from 'use-query-params'
import { useAuth } from '../../commons/Auth'
import { create } from '../../lib/fetch/profiles'
import {list} from '../../lib/fetch/invoices'
import TablePresentational from './InvoicesPresentational'

function List(props) {
  const { history } = props
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const { t } = useTranslation('FORGOT')
  const [{ token } = {}] = useAuth()
  const alert = useAlert()

  const [query, setQuery] = useQueryParams({
    from: StringParam,
    to: StringParam,
    search: StringParam,
  })

  const { from, to, search } = query

  useEffect(() => {
    const fetchList = async () => {
      setLoading(true)
      const { error, data, headers } = await list(token, query)

      if (error) {
        setData([])
        setTotal(0)
        setLoading(false)
        alert.error(t('COMMON:GENERIC_ERROR'))
        return
      }

      const mappedData = data.map((invoice) => ({
        recipient: invoice.CustomerRef.name,
        invoice: invoice.Line[0] && invoice.Line[0].Description,
        number: invoice.Id,
        date: invoice.TxnDate,
      }))
      setData(mappedData)
      setTotal(Number(headers['x-total-count']))
      setLoading(false)
    }

    fetchList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to, search])

  const onCreate = async () => {
    const defaultData = {}

    setLoading(true)
    const { error, data } = await create({ data: defaultData, token })
    setLoading(false)

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'))
      return
    }

    history.push(`/contact-detail/${data.id}`)
  }

  return <TablePresentational data={data} total={20} query={query} onCreate={onCreate} onFilter={setQuery} isLoading={isLoading} {...props} />
}

export default List
