import React, { useState } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input,
} from 'reactstrap';
import './ModalKey.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next'

function ModalKey({ onConfirm = () => {} }) {
  const [t] = useTranslation('PARTNERS');
  const [modal, setModal] = useState();
  const [username, setUsername] = useState('');
  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button color="danger" onClick={toggle}>{t('ADD_KEY')}</Button>
      <Modal isOpen={modal} fade={false} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('ADD_KEY')}</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label>{t('USERNAME_LABEL')}</Label>
              <Input
                type="text"
                name="username"
                onChange={({ target }) => setUsername(target.value)}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => { onConfirm(username); toggle(); }}>
            <img src="/images/icn-plus.svg"/>
            {t('ADD_KEY')}
          </Button>
          <Button color="secondary" onClick={toggle}>{t('COMMON:CANCEL')}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalKey;
