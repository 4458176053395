import React from 'react'
import './Footer.scss'
import { version } from './../../../package.json'

function Footer() {
  const currentYear = new Date().getFullYear()

  return (
    <footer>
      <div className="row">
        Copyright® Palmabit {currentYear} v.{version}
      </div>
    </footer>
  )
}

export default Footer
