import React from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import Menu from '../../commons/Menu'
import BurgerMenu from '../../commons/BurgerMenu'
import Header from '../../commons/Header'
import Footer from '../../commons/Footer'
import { ReactComponent as IconCompany } from '../../images/icn_aziende.svg'
import PartnersTable from './PartnersTable'
import './Partners.scss'
import ModalKey from '../../commons/ModalKey'

function PartnersPresentational(props) {
  const [t] = useTranslation();

  const { createKey, data, isLoading, total = 0 } = props

  return (
    <div className="App">
      <Menu />
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header title={t('PARTNERS:TITLE')} icon={<IconCompany fill="#2F5D5D" />} />

          <div className="row search-add-cont">
            <div className="col-sm-12 col-md-6 col-lg-8">
            </div>

            <div className="col-sm-12 col-md-6 col-lg-4">
              <ModalKey onConfirm={createKey} />
            </div>
          </div>

          <hr />

          <Card>
            <CardBody>
              <PartnersTable
                data={data}
                isLoading={isLoading}
                total={total}
              />
            </CardBody>
          </Card>
        </section>
        <Footer />
      </div>
    </div>
  )
}

export default withRouter(PartnersPresentational)
