import './CSVDownload.scss'
import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'
import { ReactComponent as IconDownload } from '../../images/icn-download.svg'
import Spinner from '../Spinner'
import { CSVLink } from 'react-csv'
import ModalConfirm from '../ModalConfirm'

function CSVDownloader({ isLoading, fetchCsvData, fileName }) {
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  const CSVLinkRef = useRef()
  const [t] = useTranslation('COMMON')

  const getData = async () => {
    const { headers, data } = await fetchCsvData()
    setHeaders(headers)
    setData(data)

    const { current: { link = {} } = {} } = CSVLinkRef
    typeof link.click === 'function' && link.click()
  }

  return isLoading && !headers && !data ? (
    <Spinner />
  ) : (
    <>
      <ModalConfirm title={t('DOWNLOAD_CSV')} text={t('TEXT_DOWNLOAD_CSV')} onConfirm={getData}>
        <Button className="btn-plus">
          <IconDownload fill="#fff" />
          <>{t('COMMON:DOWNLOAD_CSV')}</>
        </Button>
      </ModalConfirm>

      {headers.length > 0 && data.length > 0 && <CSVLink filename={`${fileName}.csv`} headers={headers} data={data} target="_blank" ref={CSVLinkRef} />}
    </>
  )
}

export default CSVDownloader
