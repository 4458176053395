import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { DataTable, Field, DateTimeField } from '../../commons/DataTable'

function PartnerCalls({ calls = [] }) {
  const [t] = useTranslation('PARTNERS');

  return (
    <Card>
      <CardBody>
        <DataTable
          data={calls}
          onFilter={() => {}}
          emptyText={t('COMMON:NO_DATA')}
          loadingText={t('COMMON:LOADING')}
        >
          <DateTimeField title={t('PERIOD')} format="MM/YYYY" source="billingPeriod" />
          <Field title={t('CALLS_COUNT')} source="hit" />
        </DataTable>
      </CardBody>
    </Card>
  )
}

export default PartnerCalls
