import React from 'react';
import { Badge } from 'reactstrap';

function FieldType({ value, maxLength }) {
  if (maxLength && typeof value === 'string' && value.length > maxLength) {
    return (<Badge>{value.substr(0, maxLength)}...</Badge>);
  }

  return (<Badge>{value}</Badge>);
}

export default FieldType;
