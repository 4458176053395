import React from 'react';
import Proptypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Card, Button, CardHeader, CardBody, CardTitle,
  Form, FormGroup, Label, Input, FormText, CustomInput
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import LogoHeader from '../../commons/LogoHeader';
import './Login.scss';

function LoginPresentational({ match, onLogin }) {
  const { t } = useTranslation('LOGIN');
  const { handleSubmit, register, errors } = useForm();

  return (
    <div className="App">
      <LogoHeader match={match} />

      <section>
        <div className="container">

          <Card className="u-center-horizontal login-card">
            <CardHeader>
              <CardTitle>
                <span>{t('DO_LOGIN')}</span>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit(onLogin)}>
                <FormGroup>
                  <Label>{t('COMMON:EMAIL')}</Label>
                  <Input
                    type="email"
                    name="email"
                    innerRef={register({
                      required: t('COMMON:REQUIRED'),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: t('COMMON:INVALID_EMAIL')
                      }
                    })}
                  />
                  {
                    errors.email && errors.email.message &&
                    <FormText className="error-message">{errors.email.message}</FormText>
                  }
                </FormGroup>

                <FormGroup>
                  <Label>{t('PASSWORD')}</Label>
                  <Input
                    type="password"
                    name="password"
                    innerRef={register({
                      required: t('COMMON:REQUIRED'),
                      pattern: {
                        minLength: 6,
                        maxLength: 20,
                        message: t('COMMON:INVALID_PASSWORD')
                      }
                    })}
                  />
                  {
                    errors.password && errors.password.message &&
                    <FormText className="error-message">{errors.password.message}</FormText>
                  }
                </FormGroup>

                <FormGroup>
                  <div className="row">
                    <div className="col-6">
                      <CustomInput type="checkbox" id="Checkbox" label={t('REMEMBER_ME')} />
                    </div>
                    <div className="col-6 text-right">
                      <Label>
                        <i>
                          <Link to={'/forgot'}>{t('FORGOT')}</Link>
                        </i>
                      </Label>
                      <br />
                      <Label>
                        <i>
                          <Link to={'/reset'}>{t('RESET')}</Link>
                        </i>
                      </Label>
                    </div>
                  </div>
                </FormGroup>
                <Button type="submit" className="u-center-horizontal">{t('LOGIN')}</Button>
              </Form>
            </CardBody>
          </Card>

        </div>
      </section>
    </div>
  );
}

LoginPresentational.propTypes = {
  onLogin: Proptypes.func.isRequired,
};

export default LoginPresentational;
