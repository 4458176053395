import axios from 'axios'

export const list = async () => {

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_PARTNERS_URL}/apikey`,
      headers: {
        'x-api-key': process.env.REACT_APP_PARTNERS_APIKEY
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const get = async (id) => {

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_PARTNERS_URL}/apikey/${id}`,
      headers: {
        'x-api-key': process.env.REACT_APP_PARTNERS_APIKEY
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const create = async (username) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_PARTNERS_URL}/apikey`,
      data: username ? { username } : {},
      headers: {
        'x-api-key': process.env.REACT_APP_PARTNERS_APIKEY
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const save = async (data = {}) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_PARTNERS_URL}/apikey`,
      headers: {
        'x-api-key': process.env.REACT_APP_PARTNERS_APIKEY
      },
      data,
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const remove = async (userId) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_PARTNERS_URL}/apikey`,
      headers: {
        'x-api-key': process.env.REACT_APP_PARTNERS_APIKEY
      },
      data: { userId },
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}
