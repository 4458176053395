export const formatProfileType = (type) => {
  switch (type) {
    case 'company':
      return "SiWeGO Company";
    case 'private':
      return "SiWeGO Easy";
    case 'professional':
      return "SiWeGO PROfessional";
    default:
      return '';
  }
}

export const formatName = (profile = {}) => {
  const { firstName = '', lastName = '', type = '', vatInfo = {} } = profile || {}
  const { companyName = '' } = vatInfo || {}
  if (type === 'professional') {
    return companyName
  }
  return `${firstName} ${lastName}`
}

export const getCompanyName = (profile = {}) => {
  const { vatInfo = {} } = profile || {}
  const { companyName = '' } = vatInfo || {}
  return companyName
}