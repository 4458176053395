import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAlert } from 'react-alert'
import { useAuth } from '../../commons/Auth'
import dashboard from '../../lib/fetch/dashboard'
import DashboardPresentational from './DashboardPresentational'
import { useQueryParams, StringParam } from 'use-query-params'


function Dashboard(props) {
  const [auth = {}] = useAuth();
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const { t } = useTranslation()
  const alert = useAlert()
  const { token = '' } = auth;

  const [query, setQuery] = useQueryParams({
    year: StringParam,
  })


  const fetchDashboard = async () => {
    setLoading(true)
    const { error, data } = await dashboard(token, query)

    if (error) {
      setData({})
      setLoading(false)
      alert.error(t('COMMON:GENERIC_ERROR'))
      return
    }

    setData(data)
    setLoading(false)
  }

  useEffect(() => {
    fetchDashboard()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return <DashboardPresentational isLoading={isLoading} data={data} query={query} onFilter={setQuery} {...props} />
}

export default Dashboard
