import React from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { DataTable, DateTimeField, Field } from '../../commons/DataTable'

function PartnersTable({ history, isLoading, data, total = 0 }) {
  const [t] = useTranslation('COMMON')

  return (
    <DataTable
      total={total}
      data={data}
      isLoading={isLoading}
      loadingText={t('COMMON:LOADING')}
      emptyText={t('COMMON:NO_DATA')}
      onFilter={() => {}}
      onRowClick={({ userId } = {}) => history.push(`/partners/${userId}/info`)}
    >
      <Field title="Username" source="username" />
      <Field title="API Key" source="key" maxLength="70"/>
      <DateTimeField title="Scadenza" format="DD/MM/YYYY" source="expire" />
      <Field title={t('PARTNERS:CALLS_COUNT')} source="totalCount" />
    </DataTable>
  )
}

export default withRouter(PartnersTable)
