import React from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import Menu from '../../commons/Menu'
import BurgerMenu from '../../commons/BurgerMenu'
import Header from '../../commons/Header'
import Footer from '../../commons/Footer'
import { ReactComponent as IconInvoices } from '../../images/icn_invoice.svg'
import Table from './Table'
import './Invoices.scss'

function InvoicesPresentational(props) {
  const [t] = useTranslation('INVOICES')

  const { data, total = 0, query, onFilter, isLoading = false } = props

  return (
    <div className="App">
      <Menu />
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header title={t('TITLE')} icon={<IconInvoices fill="#2F5D5D" />} />

          <hr />

          <Card>
            <CardBody>
              <Table data={data} total={total} query={query} onFilter={onFilter} isLoading={isLoading} />
            </CardBody>
          </Card>
        </section>
        <Footer />
      </div>
    </div>
  )
}

export default withRouter(InvoicesPresentational)
