import './Dashboard.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter, Link } from 'react-router-dom'
import Menu from '../../commons/Menu'
import BurgerMenu from '../../commons/BurgerMenu'
import Header from '../../commons/Header'
import Footer from '../../commons/Footer'
import Kpi from '../../commons/Kpi'
import { ReactComponent as IconDashboard } from '../../images/icn_dashboard.svg'
import PieChartCard from '../../components/PieChartCard'
import YearInput from '../../commons/YearInput';

import moment from 'moment'
//import LineChartCard from '../../components/LineChartCard'

function DashboardPresentational({ match, data, query, onFilter }) {
  const {year} = query
  const [t] = useTranslation('COMMON')
  const {
    cashedFees = 0,
    totalTransited = 0,
    totalProfiles = 0,
    activeProfiles = 0,
    inactiveProfiles = 0,
    totalAllTravels = 0,
    totalShippingsNoMatch = 0,
    totalTravelsNoMatch = 0,
    totalShippings = 0
  } = data

  return (
    <div className="App">
      <Menu match={match} />
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header title={t('COMMON:DASHBOARD')} icon={<IconDashboard fill="#2F5D5D" />} />
        </section>

        <section>
          <div className="row">
            <div className="year-dropdown col-sm-6 col-md-3 col-lg-3">
              <YearInput value={year} onChange={onFilter}/>
            </div>
          </div>
        </section>

        <hr />

        <section>
          <h4>KPI <small>(relativi al {moment(year).year()})</small></h4>
          <div className="kpi-cont">
            <Kpi title="Tot. transato" unitLabel="euro" value={totalTransited} />
            <Kpi title="FEE incassate" unitLabel="euro" value={cashedFees} />
            <Kpi title="Utenti registrati" unitLabel="utenti" value={totalProfiles} />
            <Kpi title="Utenti attivi" unitLabel="utenti" value={activeProfiles} />
            <Kpi title="Utenti inattivi" unitLabel="utenti" value={inactiveProfiles} />
            <Link to="/deliveries?type=send">
              <Kpi title="Tot. spedizioni" unitLabel="spedizioni" value={totalShippings} />
            </Link>
            <Link to="/deliveries?type=send&matched=false">
              <Kpi title="Tot. spedizioni senza match" unitLabel="spedizioni" value={totalShippingsNoMatch} />
            </Link>
            <Link to="/deliveries?type=carry">
              <Kpi title="Tot. trasporti" unitLabel="trasporti" value={totalAllTravels} />
            </Link>
            <Link to="/deliveries?type=carry&matched=false">
              <Kpi title="Tot. trasporti senza match" unitLabel="trasporti" value={totalTravelsNoMatch} />
            </Link>
          </div>
        </section>

        <section>
          <div className="row">
            {/* LINE CHART */}
            {/* <div className="col-sm-12 col-md-6">
              <h4>Grafico</h4>
              <LineChartCard data={data} />
            </div> */}
            <div className="col-sm-12 col-md-12">
              <h4>Grafico <small>(relativo al {moment(year).year()})</small></h4>
              <PieChartCard values={data} />
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  )
}

export default withRouter(DashboardPresentational)
