import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardBody } from 'reactstrap'
import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Tooltip } from 'recharts'

const PieChartCard = props => {
  const { values = {} } = props
  const { totalTravels = 0, ongoingTravels = 0, completedTravels = 0, expiredTravels = 0 } = values

  const data = [
    {
      value: totalTravels,
      name: 'Spedizioni inserite',
      color: '#51A3A3',
    },
    {
      value: ongoingTravels,
      name: 'Spedizioni in corso',
      color: '#2F5D5D',
    },
    {
      value: completedTravels,
      name: 'Spedizioni completate',
      color: '#BEDB39',
    },
    {
      value: expiredTravels,
      name: 'Spedizioni scadute',
      color: '#D87373',
    },
  ]

  function renderLabel({ percent }) {
    return `${(percent * 100).toFixed(2)}%`
  }

  return (
    <Card>
      <CardBody>
        <ResponsiveContainer width="100%" height={400}>
          <PieChart margin={{ bottom: 20 }}>
            <Pie data={data} dataKey="value" nameKey="name" isAnimationActive={false} label={renderLabel}>
              {data.map(({ color } = {}, i) => (
                <Cell key={i} fill={color} />
              ))}
            </Pie>
            <Tooltip />
            <Legend
              payload={data.map(({ name, color } = {}, i) => ({
                id: i,
                value: name,
                type: 'circle',
                color,
              }))}
            />
          </PieChart>
        </ResponsiveContainer>
      </CardBody>
    </Card>
  )
}

PieChartCard.propTypes = {
  values: PropTypes.object.isRequired,
}

export default PieChartCard
