import axios from 'axios'
import qs from 'qs'

export const list = async (token, params = {}) => {
  const { offset = 0, order = 'ASC', sort = 'firstName', limit = process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT, search, emailVerified } = params
  const queryString = { offset, order, sort, limit, search, emailVerified }

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/profiles?${qs.stringify(queryString)}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const get = async (token, id) => {

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/profiles/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const create = async ({ data, token } = {}) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/profiles`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const save = async ({ id, token, data = {} } = {}) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/profiles/${id}`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const remove = async ({ id, token } = {}) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/profiles/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const getKpi = async (token, { id, from, to }) => {
  const params = { from, to }

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/profiles/${id}/kpi?${qs.stringify(params)}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}
