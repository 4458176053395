import React from 'react';
import Proptypes from 'prop-types';
import {Link} from 'react-router-dom'
import {
  Card, Button, CardHeader, CardBody, CardTitle,
  Form, FormGroup, Label, Input, FormText
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import LogoHeader from '../../commons/LogoHeader';
import './Reset.scss';

function ResetPresentational({ match, onReset, resetSuccess }) {
  const { t } = useTranslation('RESET');
  const { errors, handleSubmit, getValues, register } = useForm();

  return (
    <div className="App">
      <LogoHeader match={match} />

      <section>
        <div className="container">

          <Card className="u-center-horizontal login-card">
            <CardHeader>
              <CardTitle>
                {t('RESET')}<br />
                <span>{t('USE_CODE')}</span>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit(onReset)}>
                <FormGroup>
                  <Label>{t('CODE')}</Label>
                  <Input
                    type="text"
                    name="code"
                    innerRef={register({
                      required: t('COMMON:REQUIRED'),
                      minLength: {
                        value: 5,
                        message: t('INVALID_CODE')
                      },
                      maxLength: {
                        value: 5,
                        message: t('INVALID_CODE')
                      }
                    })}
                  />
                  {
                    errors.code && errors.code.message &&
                    <FormText className="error-message">{errors.code.message}</FormText>
                  }
                  <Label>{t('COMMON:PASSWORD')}</Label>
                  <Input
                    type="password"
                    name="password"
                    innerRef={register({
                      required: t('COMMON:REQUIRED'),
                      minLength: {
                        value: 6,
                        message: t('INVALID_PASSWORD')
                      }
                    })}
                  />
                  {
                    errors.password && errors.password.message &&
                    <FormText className="error-message">{errors.password.message}</FormText>
                  }
                  <Label>{t('CONFIRM_PASSWORD')}</Label>
                  <Input
                    type="password"
                    name="confirm"
                    innerRef={register({
                      validate: value => value === getValues().password || t('INVALID_CONFIRM')
                    })}
                  />
                  {
                    errors.confirm && errors.confirm.message &&
                    <FormText className="error-message">{errors.confirm.message}</FormText>
                  }
                </FormGroup>

                <Button className="u-center-horizontal">{t('RESET')}</Button>
                {
                  resetSuccess && (
                    <FormText className="result-message">
                      {t('SUCCESS_1')}
                      <br />
                      <Link to="/">
                        {t('SUCCESS_2')}
                      </Link>
                    </FormText>
                  )
                }
              </Form>
            </CardBody>
          </Card>

        </div>
      </section>
    </div>
  );
}

ResetPresentational.propTypes = {
  onReset: Proptypes.func.isRequired,
};

export default ResetPresentational;
