import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { ReactComponent as IconDashboard } from "../../images/icn_dashboard.svg";
import { ReactComponent as IconDelivery } from "../../images/icn_spedizioni.svg";
import { ReactComponent as IconAlert } from "../../images/icn_alert.svg";
import { ReactComponent as IconInvoices } from "../../images/icn_invoice.svg";
import { ReactComponent as IconContacts } from "../../images/icn_utenti.svg";
import { ReactComponent as IconCompany } from '../../images/icn_aziende.svg';
import "./LinkMenu.scss";

function LinkMenu({ match }) {
  const { url } = match || {};

  const getActive = (url = "", paths = []) => {
    return paths.some((path) => url.startsWith(path)) ? "active" : "";
  };

  return (
    <div>
      <Link to={"/dashboard"} className={getActive(url, ["/dashboard"])}>
        <IconDashboard className="hover-color" />
      </Link>
      <Link to={"/profiles"} className={getActive(url, ["/profiles"])}>
        <IconContacts className="hover-color" />
      </Link>
      <Link to={'/partners'} className={getActive(url, ['/partners'])}>
        <IconCompany className="hover-color" />
      </Link>
      <Link to={'/deliveries'} className={getActive(url, ['/deliveries'])}>
        <IconDelivery className="hover-color" />
      </Link>
      <Link to={"/alerts"} className={getActive(url, ["/alerts"])}>
        <IconAlert className="hover-color" />
      </Link>
      {/* TODO INVOICES */}
      <Link to={"/invoices"} className={getActive(url, ["/invoices"])}>
        <IconInvoices className="hover-color" />
      </Link>
    </div>
  );
}

export default withRouter(LinkMenu);
