import React from 'react';

const getTitles = (components = []) => {
  if (!Array.isArray(components)) {
    return [];
  }

  const titles = components.map((component = {}) => {
    const { props = {} } = component;
    const { title = '', sort } = props;
    return {title, sort};
  });

  return titles;
};

const DataTableHeader = ({ children = {}, query, onFilter }) => {
  const titles = getTitles(children);

  function setSort(sort) {
    if (!sort) return
    onFilter(
      {
        ...query,
        sort,
        order: query.order === 'ASC' ? 'DESC' : 'ASC'
      })

  }

  return (
    <thead>
      <tr>
        {
          titles.map(({title = '', sort}, key) => (<th key={key} onClick={() => setSort(sort)}>{title}</th>))
        }
      </tr>
    </thead>
  )
};

export { getTitles, DataTableHeader };
