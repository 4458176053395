import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, FormGroup, Label } from 'reactstrap';
import moment from 'moment';
import DateInput from '../DateInput';

const formatDate = date => moment(date).format('YYYY-MM-DD');

function ProfileTransactionsFilters({ query = {}, onFilter } = {}) {
  const [t] = useTranslation();
  const { from, to } = query;

  return (
    <Form inline className="filter" onSubmit={(event) => event.preventDefault()}>
      <FormGroup>
        <Label for="Date">{t('COMMON:FROM')}</Label>
        <DateInput
          onChange={date => onFilter({ from: date && formatDate(date) })}
          disablePopup={true}
          value={from}
          className="mx-sm-4"
          name="from"
        />
      </FormGroup>
      <FormGroup >
        <Label for="Time">{t('COMMON:TO')}</Label>
        <DateInput
          onChange={date => onFilter({ to: date && formatDate(date) })}
          disablePopup={true}
          value={to}
          className="mx-sm-4"
          name="to"
        />
      </FormGroup>
    </Form>
  );
}

ProfileTransactionsFilters.propTypes = {
  query: PropTypes.object,
  onFilter: PropTypes.func.isRequired,
};

export default ProfileTransactionsFilters;
