import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { create, list } from '../../lib/fetch/partners';
import PartnersPresentational from './PartnersPresentational';

function Partners(props) {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();
  const alert = useAlert();

  const fetchPartners = async () => {
    setLoading(true);
    const { error, data } = await list();

    if (error) {
      setData([]);
      setTotal(0);
      setLoading(false);
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    const partners = data.map(partner => {
      const { hitCounts } = partner || {};
      const totalCount = (hitCounts || []).reduce((acc, { hit = 0 } = {}) => acc + hit, 0);
      return {
        ...partner,
        totalCount,
      };
    });

    setData(partners);
    setTotal(partners.length);
    setLoading(false);
  }

  const createKey = async (username) => {
    setLoading(true);
    const { error } = await create(username);
    setLoading(false);

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    await fetchPartners();
  };

  useEffect(() => {
    fetchPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <PartnersPresentational
      data={data}
      total={total}
      isLoading={isLoading}
      createKey={createKey}
      {...props}
    />
  )
}

export default Partners;
