import React from 'react'
import { InputGroup, InputGroupAddon, Button, Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import './SearchInput.scss'
import { useDebouncedCallback } from 'use-debounce'

function SearchInput({ onFilter, query: { search = '' } = {} }) {
  const [t] = useTranslation()
  const [debouncedOnFilter] = useDebouncedCallback(value => onFilter(value), 800)

  return (
    <InputGroup className="search-bar">
      <Input defaultValue={search} onChange={e => debouncedOnFilter({ search: e.target.value, offset: 0 })} placeholder={t('COMMON:SEARCH')} />
      <InputGroupAddon addonType="append">
        <Button color="secondary" onClick={() => debouncedOnFilter()}>
          <i className="fas fa-search" />
        </Button>
      </InputGroupAddon>
    </InputGroup>
  )
}

export default SearchInput
